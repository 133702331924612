import React, { Component } from "react";
import classes from "./audit.module.css";
import AuditReport from "./AuditReport";
import CreateAudit from "./CreateAudit";

class AdminAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectReport: "create",
      updateChild:""
    };
  }

  handleCallback = (childData) =>{
      this.setState({
        updateChild: childData.auditId
      });
      console.log(childData.auditId);
  }
  render() {
    // console.log("ddddd", this.state.selectReport);
    return (
      <div className={classes.audit_mainContainer}>
        <h1 className={classes.audit_heading}>Audit</h1>
        <div className={classes.audit_report}>
          <p
            className={[
              classes.audit_r_para,
              this.state.selectReport === "create" ? classes.audit_r_para1 : "",
            ].join(" ")}
            onClick={() => this.setState({ selectReport: "create" })}
          >
            Create Audit
          </p>
          <p
            className={[
              classes.audit_r_para,
              this.state.selectReport === "audit" ? classes.audit_r_para1 : "",
            ].join(" ")}
            onClick={() => this.setState({ selectReport: "audit" })}
          >
            Audit Report
          </p>
        </div>

        {/* report Containers */}
        {/* Create Audit */}

        <CreateAudit isVisible={this.state.selectReport} parentCallback={this.handleCallback}/>

        <AuditReport isVisible={this.state.selectReport} updateChild={this.state.updateChild} />

        {/*  Audit Report */}
      </div>
    );
  }
}

export default AdminAudit;
