import React, { Component } from "react";
import classes from "./career.module.css";

const data = [
  {
    content: "Career Path",
    content2: "Assigned To Employee",
    value: "400",
  },
  {
    content: "Career Path",
    content2: "Requested",
    value: "200",
  },
  {
    content: "Upward Path",
    content2: "Proposed",
    value: "100",
  },
  {
    content: "Alternate Path",
    content2: "Proposed",
    value: "50",
  },
  {
    content: "Denied",
    content2: "Career Path",
    value: "40",
  },
  {
    content: "Proposed Path",
    content2: "Moved To Roled",
    value: "10",
  },
];
let color = ["#BDA4FC", "#ff8c96", "#96ACF9", "#E57373", "#4D4CAC", "#009CE0"];

class CareerPath extends Component {
  render() {
    // console.log(this.props.careerPathData);
    return (
      <div className={classes.career_div}>
        <h2 className={classes.heading}>{this.props.tableTata}</h2>
        <div className={classes.centerDiv}>
          <div className={classes.careerPath_items}>
            {this.props.careerPathData?.map((item, index) => (
              <div
                key={index}
                className={classes.careerPath_box}
                style={{ backgroundColor: color[index] }}
              >
                <p className={classes.item_text_number}>{item.value}</p>

                <div className={classes.textSection}>
                  <p className={classes.item_text}>{item.content}</p>
                  {/* <p className={classes.item_text}>{item.content2}</p> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CareerPath;
