import React, { PureComponent } from "react";
import { PieChart, Pie, Cell } from "recharts";
import classes from "./overallskillgap.module.css";

// const data = [
//   { name: "Group A", value: 300 },
//   { name: "Group B", value: 400 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
// ];

// const COLORS = ["#BDA4FC", "#ff8c96", "#96ACF9", "#FCDC00"];
// const COLORS = ["#B2A4FF", "#53BF9D"];
const COLORS = ["#F05454", "#53BF9D"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontWeight={"bold"}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
class OverAllSkillGap extends PureComponent {
  render() {
    // console.log("42", this.props.skillGap);
    const data = [
      { skillGap: Number(this.props.skillGap.OverallSkillGap) },
      { skillGap: Number(this.props.skillGap.SkillGapCovered) },
    ];
    // console.log("type", data);
    if (data) {
      return (
        <div>
          <h2 className={classes.overAllSkill}>Overall Skill Gap</h2>

          <div className={classes.pieChart}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                fill="#8884d8"
                dataKey="skillGap"
              >
                {COLORS.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div>
          <div style={{ position: "relative", width: "100%", display: "flex" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#F05454",
                }}
              ></div>
              <p style={{ margin: "0" }}>Overall Skill Gap</p>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#53BF9D",
                }}
              ></div>
              <p style={{ margin: "0" }}>Skill Gap Covered</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h2 className={classes.overAllSkill}>No Data Available</h2>

          {/* <div className={classes.pieChart}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                fill="#8884d8"
                dataKey="skillGap"
              >
                {COLORS.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </div> */}
          {/* <div style={{ position: "relative", width: "100%", display: "flex" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#F05454",
                }}
              ></div>
              <p style={{ margin: "0" }}>Overall Skill Gap</p>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "#53BF9D",
                }}
              ></div>
              <p style={{ margin: "0" }}>Skill Gap Covered</p>
            </div>
          </div> */}
        </div>
      );
    }
  }
}

export default OverAllSkillGap;
