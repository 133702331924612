import React, { Component } from "react";
import DisplayPicture from "../../../DisplayPicture";
import Star from "../../../../assets/images/star.svg";
import Medal from "../../../../assets/images/medal-big.svg";
import Favorites from "../../../../assets/images/Favorite.svg";
import nonFavorites from "../../../../assets/images/nonFavorite.svg";
class TableRow extends Component {
  render() {
    return (
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          minHeight: 58,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          fontFamily: "Open Sans Regular",
          fontSize: "0.875em",
          color: "#303030",
          background: "#FFFFFF",
          borderRadius: "4px",
        }}
      >
        <tbody>
        <tr style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <td style={{ flex: 0.9, display: "flex", alignItems: "center", marginLeft: "22px" }}>
            {this.props.departmentName}
          </td>
          <td style={{ flex: 0.9 }}>{this.props.avgLeaderboardPoints}</td>
          <td style={{ flex: 0.58 }}>{this.props.pointsMoved}</td>
          
        </tr>
        </tbody>
      </table>
    );
  }
}

export default TableRow;
