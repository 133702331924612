import React, { PureComponent } from "react";
import classes from "./overallskillgap.module.css";

class ProgressBar extends PureComponent {
  render(props) {
    const Parentdiv = {
      height: "15px",
      width: "100%",
      backgroundColor: this.props.bgcolor,
      borderRadius: "10px",
    };

    const Childdiv = {
      height: "100%",
      width: this.props.progress,
      backgroundColor: this.props.childBgColor,
      borderRadius: "10px",
      textAlign: "right",
    };

    return (
      <>
        <span className={classes.heading}>{this.props.title}</span>
        <div style={Parentdiv} className={classes.parent_div}>
          <div style={Childdiv} className={classes.child_div}>
            <span
              className={classes.progresstext}
            >{`${this.props.progress}`}</span>
          </div>
        </div>
      </>
    );
  }
}

export default ProgressBar;
