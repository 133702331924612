import React, { PureComponent } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import Matrix from "./compentecyMatrix/Matrix";
import classes from "./compentecyMatrix/matrix.module.css";

const data = [
  { name: "C1", value: 300 },
  { name: "C2", value: 500 },
  { name: "C3", value: 200 },
];
const renderCustomizedLabel = ({ x, y, name, percent }) => {
  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor="center"
      dominantBaseline="central"
      zIndex="999"
    >
      {` ${name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

var previousColors = [];

function getRandomColor() {
  var letters = "0123456789abcdef";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (previousColors.includes(color)) {
    return getRandomColor();
  } else {
    previousColors.push(color);
    return color;
  }
}
// function generateLimitedColor() {
//   for (let index = 0; index < 7; index++) {
//     getRandomColor();
//   }
//   return previousColors;
// }
// const COLORS = generateLimitedColor();
// const COLORS = ["#BDA4FC", "#ff8c96", "#96ACF9"];
const COLORS = ["#00A19D", "#6F69AC", "#FFB319"];
export default class AnalyticsCompetencyMatrix extends PureComponent {
  static demoUrl =
    "https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o";

  render() {
    console.log("compentencyMatrix", this.props.compentencyMatrix);

    return (
      <div className={classes.grid_item}>
        <div className={classes.zIndex}>
          <h2 className={classes.overAllSkill}>Competency Matrix</h2>
          {/* <ResponsiveContainer width="100%" height="100%"> */}
          <div className={classes.pieChartLeft}>
            <PieChart width={450} height={300} onMouseEnter={this.onPieEnter}>
              <Pie
                data={this.props.compentencyMatrix.dataarr}
                // data={[
                //   { name: "Beginner", label: "Beginner", value: 30 },
                //   { name: "Intermediate", label: "Intermediate", value: 30 },
                //   { name: "Expert", label: "Expert", value: 40 },
                // ]}
                label={renderCustomizedLabel}
                dataKey="value"
                labelLine={false}
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={100}
                outerRadius={120}
                fill="#BDA4FC"
                paddingAngle={5}
                title="hII"
              >
                <Label
                  // value={
                  //   this.props.compentencyMatrix.message !== undefined
                  //     ? this.props.compentencyMatrix.message
                  //         .totalAssesmentsTaken
                  //     : 0
                  // }
                  value={
                    this.props.compentencyMatrix.message !== undefined
                      ? this.props.compentencyMatrix.message
                          .totalAssesmentsTaken + " Total Assessments Taken"
                      : 0
                  }
                  position="center"
                />

                {COLORS.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
            </PieChart>
          </div>
          {/* </ResponsiveContainer> */}
        </div>

        <Matrix
          COLORS={COLORS}
          compentencyMatrix={this.props.compentencyMatrix}
        />
      </div>
    );
  }
}
