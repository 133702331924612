import React, { Component } from "react";
import styled from "styled-components";
import jsPDF from "jspdf";
import BusinessGroup from "../../assets/images/BusinessGroup.svg";
import Team from "../../assets/images/team.svg";
import CustomerMessage from "../../assets/images/CustomerMessage.svg";
import Presentation from "../../assets/images/presentation.svg";

import { message } from "antd";
import { getCommonApiStructure } from "../../core/apiClient/organization/organizationClient";
import { decryptData } from "../../utils/encryptDecrypt";

import Button from "../../components/Button";
import FilterDropdown from "../../components/FilterDroprdown";
import AnalyticsOverallSkill from "../../components/analytics/admin/AnalyticsOverallSkill";
import AnalyticsCompetencyMatrix from "../../components/analytics/admin/AnalyticsCompetencyMatrix";
import AttritionAnalytics from "../../components/analytics/admin/AttritionBarChart/AttritionAnalytics";

import LeaderBoardAnalytics from "../../components/analytics/admin/LeaderBoardAnalytics";
import CareerPath from "../../components/analytics/admin/career/CareerPath";
import Activity from "../../components/analytics/admin/career/Activity";
import AnalyticsCard from "../../components/analytics/admin/components/AnalyticsCard";
import html2canvas from "html2canvas";

const Div = styled.div`
  background-color: #f8f8f8;
  font-family: Open Sans Regular;
  color: #303030;
  padding: 1% 2%;
  margin-bottom: 3%;
  font-size: 16px;
  .dahboard-title {
    font-family: Open Sans Semibold;
    font-size: 1.125em;
  }

  .dashboard-sub-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }

  .dashboard-KPI-card-container {
    width: 100%;
    color: #303030;
    box-shadow: 1px 4px 12px #00000027;
    border-radius: 8px;
    height: inherit;
    padding: 1.6%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin: 16px 0;
  }
  .dashboard-kpi-cards {
    display: grid;
    grid-template-columns: repeat(
      ${(props) => (props.repeat ? props.repeat : 6)},
      1fr
    );
    grid-column-gap: 1.5%;
  }
  .dashboad-map-overall-performance-wrapper {
    display: flex;
    flex-direction: row;
  }
  .dashboard-card {
    width: 100%;
    color: #303030;
    box-shadow: 1px 4px 12px #00000027;
    border-radius: 8px;
    padding: 3%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .dashboard-card-title {
    font-family: Open Sans Semibold;
    font-size: 1.09em;
  }
  .dashboard-kpi-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5%;
  }
`;
const FilterButtonAnalytic = styled.button`
  height: 40px;
  width: 120px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  color: #767676;
`;

class AnalyticsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpiCardData: [
        {
          title: "Total Employees",
          icon: Team,
          count: 0,
          cardColor: { color1: " #fcdf9b", color2: "#f69b94" },
        },
        {
          title: "Exit Employees",
          icon: BusinessGroup,
          count: 0,
          cardColor: { color1: " #fd99af", color2: "#EE68AA" },
        },
        {
          title: "Attrition",
          icon: CustomerMessage,
          count: 0,
          cardColor: { color1: " rgb(181, 255, 230)", color2: "#74D4B0" },
        },
        {
          title: "Top On Leaderboard",
          icon: Presentation,
          count: 0,
          cardColor: { color1: " #5BDDE2", color2: "#64A7E9" },
        },
      ],
      filterOption: [],
      filterValue: [],
      skillGap: [],
      compentencyMatrix: [],
      attritionAnalyticsData: [],
      leaderBoard: [],
      careerPathData: [],
      activityData: [],
    };
  }

  componentDidMount = () => {
    this.loadDashboardKPI();
    this.loadFilterOption();
    this.skillGapFun();
    this.compentencyMatrixData();
    this.attritionAnalytics();
    // this.leaderBoardData();
    this.careerPathLoad();
    this.activityLoad();
    // this.loadDashboardAssessments(6);
    // this.loadDashboardSkillImprovement(6);
    // this.loadActivities(6)
  };

  loadDashboardKPI = async () => {
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let url = `analytics/attritionKPI/orgId/${orgId}?startTime=${startTime}&endTime=${endTime}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });
        // console.log("17", response);
        if (response.status === 200) {
          let data = response.data;
          let kpiCardData = this.state.kpiCardData;
          kpiCardData[0].count = data.totalEmployees ? data.totalEmployees : 0;
          kpiCardData[1].count = data.exitEmployees ? data.exitEmployees : 0;
          kpiCardData[2].count = data.attrition ? data.attrition + "%" : 0;

          this.setState({
            kpiCardData: kpiCardData,
            licensesAvailable: data.licensesAvailable ? true : false,
          });
        }
      } catch (error) {
        message.error("Unable to fetch details, please try after sometime");
      }
      document.toggleLoading();
    }
  };
  filterSelected = (filterValue) => {
    this.setState({ filterValue });

    //refresh other Apis
    this.skillGapFun(filterValue);
    this.compentencyMatrixData(filterValue);
    this.attritionAnalytics(filterValue);
    // this.leaderBoardData(filterValue);
    this.careerPathLoad(filterValue);
    this.activityLoad(filterValue);
  };
  loadFilterOption = async () => {
    try {
      document.toggleLoading(true);
      let orgId = decryptData(localStorage.orgId);
      let token = decryptData(localStorage.token);

      let url = `analytics/deptList/org/${orgId}`;
      let response = await getCommonApiStructure("get", url, {
        Authorization: token,
      });

      if (response.status === 200) {
        let data = response.data;

        if (data.length > 0) {
          let tempFilterOp = [];
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            tempFilterOp.push({
              label: element,
              value: element,
            });
          }
          this.setState({ filterOption: tempFilterOp });
        } else {
          document.message.error(JSON.stringify(data));
        }
      }
    } catch (error) {
      /**
       *  Temporary Work Around
       */
      // console.log("error", error);
      // document.message.error("Something went wrong! Please try again later.");
    }
    document.toggleLoading();
  };

  skillGapFun = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?startTime=${startTime}&endTime=${endTime}`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }
        let url = `analytics/skillGapAnalysis/orgId/${orgId}${extraparams}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });
        console.log("=== SDP SkillGapAnalysis response is --> ", response);
        if (response.status === 200) {
          let data = response.data;
          // console.log("data 254", data);
          this.setState({ skillGap: data });
        } else {
          this.setState({ skillGap: [] });
        }
      } catch (error) {
        return;
        console.log("Skill Gap Analysis error -- ", error);
        // document.message
        //   .error
        //   // "No Users Registered "
        //   // "Unable to fetch details, please try after sometime"
        //   ();
      }
      document.toggleLoading();
    }
  };
  compentencyMatrixData = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?startTime=${startTime}&endTime=${endTime}`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }
        let url = `analytics/competencyAnalysis/orgId/${orgId}${extraparams}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let message = response.data.message;

          let dataarr = [];
          let k = 0;
          for (const property in message) {
            if (message.hasOwnProperty(property) && k > 2) {
              dataarr.push({
                name: property,
                label: property,
                value: message[property],
              });
            }
            k++;
          }

          // console.log("da 299", response);
          this.setState({
            compentencyMatrix: { dataarr: dataarr, message: message },
          });
        }
      } catch (error) {
        console.log("error", error);
        document.message.error(
          "Unable to fetch details, please try after sometime"
        );
      }
      document.toggleLoading();
    }
  };
  attritionAnalytics = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?startTime=${startTime}&endTime=${endTime}`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }
        let url = `analytics/attrition/orgId/${orgId}${extraparams}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          let dataarr = [];
          let k = 1;
          for (const property in data) {
            if (data.hasOwnProperty(property)) {
              dataarr.push({
                name: "Q" + k,
                label: property,
                uv: data[property],
              });
            }
            k++;
          }
          // console.log("data array", dataarr);
          this.setState({ attritionAnalyticsData: dataarr });
        }
      } catch (error) {
        console.log("error", error);
        document.message.error(
          "Unable to fetch details, please try after sometime"
        );
      }
      document.toggleLoading();
    }
  };

  leaderBoardData = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      employeeId = decryptData(localStorage.employeeId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?startTime=${startTime}&endTime=${endTime}&scopeType=root&organizationRole=Employee&offset=0&limit=15&searchKey=`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }

        let url = `analytics/leaderboardAnalytics/org/${orgId}/employees/${employeeId}/list/${extraparams}`;
        let response = await getCommonApiStructure("get", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          this.setState({ leaderBoard: data });
        } else {
          this.setState({ leaderBoard: [] });
        }
      } catch (error) {
        /**
         * NOTE: This is just a workaround until revamp.
         */
        return;
        // console.log("error", error);
        // document.message.error(
        //   "Unable to fetch details, please try after sometime"
        // );
      }
      document.toggleLoading();
    }
  };
  careerPathLoad = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?Authorization=${token}&startTime=${startTime}&endTime=${endTime}`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }
        let url = `analytics/careerPath/orgId/${orgId}${extraparams}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });
        console.log("===SDP analytics/careerPath response is --> ", response);
        if (response.status === 200) {
          let data = response.data;

          let dataarr = [];
          let k = 1;
          for (const property in data) {
            if (data.hasOwnProperty(property)) {
              dataarr.push({
                content: property,
                label: property,
                value: data[property],
              });
            }
            k++;
          }
          // console.log(dataarr);
          this.setState({ careerPathData: dataarr });
        } else {
          this.setState({ careerPathData: [] });
        }
      } catch (error) {
        return;
        // console.log("error", error);
        // document.message.error(
        //   "Unable to fetch details, please try after sometime"
        // );
      }
      document.toggleLoading();
    }
  };
  activityLoad = async (filterValue = []) => {
    let orgId = decryptData(localStorage.orgId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let extraparams = `?startTime=${startTime}&endTime=${endTime}`;
        if (filterValue.length > 0) {
          extraparams += `&department=${filterValue}`;
        }
        let url = `analytics/top5Activities/orgId/${orgId}${extraparams}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          this.setState({ activityData: data });
        }
      } catch (error) {
        console.log("error", error);
        document.message.error(
          "Unable to fetch details, please try after sometime"
        );
      }
      document.toggleLoading();
    }
  };

  handleCallback = (childData) => {
    // console.log("469", childData);
    let kpiCardData = this.state.kpiCardData;
    kpiCardData[3].title = "Top On Leaderboard ";
    kpiCardData[3].count = childData.departmentName
      ? "" + childData.departmentName
      : "";
    // kpiCardData[3].count = childData.avgLeaderboardPoints
    //   ? childData.avgLeaderboardPoints
    //   : 0;

    // console.log("476", kpiCardData);
    this.setState({
      kpiCardData: kpiCardData,
    });
  };

  downloadPDF = (event) => {
    window.html2canvas = html2canvas;
    const pdfSection = document.getElementById("pdfSection");
    html2canvas(pdfSection).then((canvas) => {
      const doc = new jsPDF({
        orientation: "portrait",
        // unit: "in",
        // format: [4, 2],
        format: [canvas.width, canvas.height],
        unit: "px",
        compress: true,
      });
      const imgData = canvas.toDataURL("image/png");
      // console.log("492", canvas);
      // console.log("492", imgData);
      // doc.addImage(imgData, "JPEG", 0, 0);
      doc.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
      doc.save("download.pdf");
    });

    // // Adding the fonts.
    // doc.setFont("Inter-Regular", "normal");
    // const elements = document.getElementsByClassName(
    //   "dashboard-KPI-card-container"
    // );
    // const element = elements[0]; // assuming there's only one element with this class name
    // const html = element.innerHTML;
    // const htmlData = document.querySelector("html").innerHTML;

    // console.log(html);
    // doc.html(html, {
    // 	async callback(doc) {
    // 		await doc.save('document.pdf');
    // 	},
    // });
    // const compElement = document.querySelector("html").innerHTML;

    // var elementRm = compElement.innerHTML.getElementById("headerNotAtPDF"); // will return element
    // element.parentNode.removeChild(element); // will remove the element from DOM

    // compElement.removeChild(elementRm);
    // window.print({
    //   media: "print",
    // });
    // let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    //   mywindow.document.write(htmlData);

    //   mywindow.document.close(); // necessary for IE >= 10
    //   mywindow.focus(); // necessary for IE >= 10*/

    //   mywindow.print({
    //       media: "print"
    //   });
    //   mywindow.close();
  };
  render() {
    // console.log("526", this.state.compentencyMatrix);
    return (
      <Div repeat={this.state.licensesAvailable ? 6 : 5}>
        <div className="dahboard-title">Analytics Dashboard</div>
        <div className="dashboard-KPI-card-container">
          <div className="dashboard-kpi-cards">
            {this.state.kpiCardData.map((eachCard, eachCardIndex) => (
              <div
                style={{
                  display:
                    eachCard.title === "Number of licenses available" &&
                    !this.state.licensesAvailable
                      ? "none"
                      : "",
                }}
                key={eachCardIndex}
              >
                <AnalyticsCard
                  cardContent={{
                    title: eachCard.title,
                    numContent: eachCard.count,
                    icon: eachCard.icon,
                    cardShadow: eachCard.cardColor.color2,
                    cardColor: `linear-gradient(to bottom right, ${eachCard.cardColor.color1} 30%,  ${eachCard.cardColor.color2} 80%)`,
                  }}
                />
              </div>
            ))}
          </div>
          <div className="userFilterContainer">
            <div
              style={{ width: 134, float: "right", margin: "30px 10px" }}
              onClick={(e) => this.downloadPDF(e)}
            >
              <Button
                label="Download PDF"
                status="enable"
                styles={{
                  color: "#ffffff",
                  backgroundColor: "#F17E8A",
                  border: "#F39CA6",
                  height: "40px",
                }}
              />
            </div>
            <div style={{ width: "20%", float: "right", margin: "30px 10px" }}>
              <FilterDropdown
                style={{ width: "135px", height: "30px" }}
                filterOnchange={this.filterSelected}
                value={this.state.filterValue}
                options={this.state.filterOption || []}
              />
            </div>
          </div>
        </div>
        {/* pdf Section */}
        <section className="analyticsBoxpdf" id="pdfSection">
          <div className="dashboard-sub-container">
            <div
              className="dashboard-KPI-card-container"
              style={{ width: "100%" }}
            >
              <AnalyticsOverallSkill skillGap={this.state.skillGap} />
            </div>
            <div
              className="dashboard-KPI-card-container"
              style={{ width: "100%" }}
            >
              <AnalyticsCompetencyMatrix
                compentencyMatrix={this.state.compentencyMatrix}
              />
            </div>
          </div>
          <div className="dashboard-sub-container">
            <div className="dashboard-KPI-card-container">
              <AttritionAnalytics
                tableTata={"Attrition Analytics"}
                attritionAnalyticsData={this.state.attritionAnalyticsData}
                scopeType={"global"}
              />
            </div>
            <div className="dashboard-KPI-card-container">
              <LeaderBoardAnalytics
                tableTata={"Leaderboard Analytics"}
                scopeType={"global"}
                filterValue={this.state.filterValue}
                leaderBoard={this.props.leaderBoard}
                parentCallback={this.handleCallback}
                // userData={this.state.userData}
                // totalEmployeeCount={this.state.totalEmployeeCount}
                // statusHandler={this.statusHandler}
                // onClickOfList={this.props.onClickOfList}
                // paginationHandler={this.paginationHandler}
              />
            </div>
          </div>
          <div className="dashboard-sub-container">
            <div className="dashboard-KPI-card-container">
              <CareerPath
                tableTata={"Career Path"}
                careerPathData={
                  this.state.careerPathData ? this.state.careerPathData : []
                }
              />
            </div>
            <div className="dashboard-KPI-card-container">
              <Activity
                tableTata={"Activity"}
                activityData={this.state.activityData}
              />
            </div>
          </div>
        </section>
      </Div>
    );
  }
}

export default AnalyticsPage;
