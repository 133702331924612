import React, { Component } from "react";
import TableRowTable from "./components/TableRowTable";
import { decryptData } from "../../../utils/encryptDecrypt";
import { getAllLeaderboardEmployees } from "../../../core/apiClient/leaderboard/leaderboardClient";
import { Empty } from "antd";
import SearchTextBox from "../../SearchTextBox";
import { withRouter } from "react-router-dom";
import { getCommonApiStructure } from "../../../core/apiClient/organization/organizationClient";

const data = [
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
  {
    departmentName: "Dammy Department",
    leaderBoardsPts: "65",
    positionMoved: "True",
  },
];
class LeaderBoardAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      employeeRegion: [],
      employeeGlobal: [],
      styles: this.props.styles,
      tableHeadings: [
        { title: "Department Name", flex: 0.6 },
        { title: "Leaderboard Ptc", flex: 0.6 },
        { title: "Position Moved", flex: 0.58 },
      ],
      searchValue: "",
      rowsPerPage: 15,
      page: 1,
      totalEmployeeCount: 0,
    };
  }
  componentDidMount = () => {
    this.setState(
      {
        scopeType: "root",
        referenceId: this.props.match.params.referenceId,
      },
      () => this.loadLeaderBoardList()
    );
  };

  loadLeaderBoardList = async () => {
    let orgId = decryptData(localStorage.orgId),
      employeeId = decryptData(localStorage.employeeId),
      token = decryptData(localStorage.token);
    if (orgId) {
      try {
        document.toggleLoading(true);
        let startTime = Date.now();
        let endTime = Date.now();
        let offset = (this.state.page - 1) * this.state.rowsPerPage;
        let extraparams = `?startTime=${startTime}&endTime=${endTime}&scopeType=root&organizationRole=Employee&offset=${offset}&limit=${this.state.rowsPerPage}&searchKey=`;
        if (this.props.filterValue.length > 0) {
          extraparams += `&department=${this.props.filterValue}`;
        }

        let url = `analytics/leaderboardAnalytics/org/${orgId}/employees/${employeeId}/list/${extraparams}`;
        let response = await getCommonApiStructure("get", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          this.setState({ tableData: data.records });
          this.setState({ totalEmployeeCount: data.recordLength });

          if (data.records.length > 0) {
            this.props.parentCallback(data.records[0]);
          }
        }
      } catch (error) {
        return;
        /**
         *  NOTE: setting toggle to False (Temp workaround)
         */
        // console.log("error", error);
        // document.message.error("No Data is available to display");
      }
      /**
       *  NOTE: setting toggle to False (Temp workaround)
       */
      document.toggleLoading(false);
    }
  };

  // inputChangeHandler = (e) => {};
  paginationHandler = (rowsPerPage, page) => {
    this.setState(
      {
        rowsPerPage: rowsPerPage,
        page: page,
      },
      () => this.loadLeaderBoardList()
    );
  };

  render() {
    // console.log("HH", this.state);
    return (
      <div
        style={{
          width: "100%",
          background: "#F8F8F8",
          fontFamily: "Open Sans Regular",
          color: "#303030",
        }}
      >
        <h3>{this.props.tableTata}</h3>
        <div>
          {/* {this.state.tableData.length > 0 ? ( */}
          <TableRowTable
            tableData={this.state.tableData}
            styles={this.state.styles}
            tableHeadings={this.state.tableHeadings}
            userRole={this.props.userRole}
            paginationHandler={this.paginationHandler}
            totalEmployeeCount={this.state.totalEmployeeCount}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
          />
          {/* ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <Empty description={<span>No Data available</span>} />
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

export default withRouter(LeaderBoardAnalytics);
