import { Table } from "antd";
import React, { useEffect, useState } from "react";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Risk Index",
    dataIndex: "riskIndex",
  },
];
const data1 = [
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhay", email: "abay@gmail.com", riskindex: "male" },
  { name: "abhaya", email: "abay@gmail.com", riskindex: "male" },
];

const TopRiskEmp = ({ tblData }) => {
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 6,
    },
  });
  useEffect(() => {}, [JSON.stringify(tableParams)]);
  useEffect(() => {
    loadRiskDate();
  }, []);

  const loadRiskDate = () => {
    console.log("Api calling...");
  };
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      ...sorter,
    });
  };
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <Table
        style={{}}
        columns={columns}
        // rowKey={(record) => record.login.uuid}
        dataSource={tblData}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default TopRiskEmp;
