import React, { Component } from "react";
import ProgressBar from "./ProgressBar";
import classes from "./overallskillgap.module.css";

const color = {
  Technical: "#5E7CE2",
  Functional: "#F26419",
  Interpersonal: "#48CAE4",
  "Stake holder": "#FFB319",
};

// const color = {
//   Technical: "#00454A",
//   Functional: "#4E31AA",
//   Interpersonal: "#144272",
//   "Stake holder": "#382039",
// };
class Progress extends Component {
  render() {
    let data = this.props.skillGap.SkillGapByType;
    console.log("=== SDP SkillGapByType --> ", data);

    return (
      <div className={classes.progress}>
        {data &&
          data.map((value, index) => {
            return (
              <ProgressBar
                key={index}
                bgcolor={"#F2D1D1"}
                progress={`${value.skillGap}%`}
                // progress={`${50}%`}
                childBgColor={color[value.skillname]}
                title={value.skillname}
              />
            );
          })}
        {/* <ProgressBar
          bgcolor={"blue"}
          progress={"50%"}
          childBgColor={"orange"}
          title={"Technical"}
        />
        <ProgressBar
          bgcolor={"#fc9ebd"}
          progress={"70%"}
          childBgColor={"#006600"}
          title={"Functional"}
        />
        <ProgressBar
          bgcolor={"#006600"}
          progress={"65%"}
          childBgColor={"#461d7c"}
          title={"Inter Personal"}
        />
        <ProgressBar
          bgcolor={"#fdd023"}
          progress={"80%"}
          childBgColor={"#461d7c"}
          title={"Stake Holder"}
        /> */}
      </div>
    );
  }
}

export default Progress;
