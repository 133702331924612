import React, { Component } from "react";
import ActivityProgess from "./ActivityProgess";
import classes from "./career.module.css";

const data = [
  {
    value: "100",
    content: "On Boarding Process Completed",
  },
  {
    value: "80",
    content: "Assessments Taken",
  },
  {
    value: "60",
    content: "Technical Assessments",
  },
  {
    value: "20",
    content: "Inter Personal Assessments",
  },
  {
    value: "350",
    content: "Login Activity",
  },
];
class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let dataSc = this.props.activityData;
    let total = dataSc.reduce((acc, curr) => acc + curr.activityCount, 0);
    let num = [];
    for (let i = 0; i < dataSc.length; i++) {
      // console.log("iii", dataSc[i]);
      // num += ;
      num.push(dataSc[i].activityCount);
    }
    // console.log("ssss", num);
    let color = ["#BDA4FC", "#ff8c96", "#96ACF9", "#4D4CAC", "#F47373"];
    return (
      <div className={classes.activity_div}>
        <h2 className={classes.heading}>{this.props.tableTata}</h2>
        <div className={classes.activity_items}>
          <ActivityProgess
            visualParts={[
              {
                percentage: `${(num[0] / total) * 100}%`,
                color: "#BDA4FC",
              },
              {
                percentage: `${(num[1] / total) * 100}%`,
                color: "#ff8c96",
              },
              {
                percentage: `${(num[2] / total) * 100}%`,
                color: "#96ACF9",
              },
              {
                percentage: `${(num[3] / total) * 100}%`,
                color: "#4D4CAC",
              },
              {
                percentage: `${(num[4] / total) * 100}%`,
                color: "#F47373",
              },
            ]}
          />
        </div>
        <div className={classes.items_activity}>
          {this.props.activityData?.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.item_activity}
                style={{ backgroundColor: color[index] }}
              >
                <p className={classes.item_activitytext}>{item.activityName}</p>
                <p className={classes.item_activitytext_number}>
                  {item.activityCount}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Activity;
