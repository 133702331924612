import React, { Component } from "react";
import OverAllSkillGap from "./OverAllSkillGap/OverallskillGap";
import Progress from "./OverAllSkillGap/Progress";
import classess from "./OverAllSkillGap/overallskillgap.module.css";

class AnalyticsOverallSkill extends Component {
  render() {
    console.log("aaaa", this.props.skillGap);
    return (
      <div className={classess.overSkillDiv}>
        <OverAllSkillGap skillGap={this.props.skillGap} />
        <Progress skillGap={this.props.skillGap} />
      </div>
    );
  }
}

export default AnalyticsOverallSkill;
