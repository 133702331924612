import React, { Component } from "react";
import classes from "./audit.module.css";

import { Select, Space, Button, DatePicker, message } from "antd";
import moment from "moment";

import { decryptData } from "../../utils/encryptDecrypt";
import { getCommonApiStructure } from "../../core/apiClient/organization/organizationClient";
import iconCopy from "../../assets/images/copy_icon.png";
const { RangePicker } = DatePicker;

class CreateAudit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditTypeData: [],
      auditTypeSelected: "",
      auditName: "",
      numEmp: "",
      auditDate: null,
      questions: [],
      generatedLink: "",
      auditId: "",
    };
  }

  componentDidMount = () => {
    this.auditTypeList();
  };

  auditTypeSelected = (value) => {
    this.setState({ auditTypeSelected: value });
  };

  handleChange = (event) => {
    // console.log(event.target.name);
    this.setState({ [event.target.name]: event.target.value });
  };
  dateSet = (val) => {
    // console.log(val[0]._d);
    // console.log(moment(val[0]._d));
    // console.log(moment(val[0]._d).format("X"));
    function epoch(date) {
      return Date.parse(date);
    }
    this.setState({
      auditDate: {
        // startDate: moment(val[0]._d).format("X"),
        // endDate: moment(val[1]._d).format("X"),
        startDate: epoch(val[0]._d),
        endDate: epoch(val[1]._d),
      },
    });
    function epoch(date) {
      return Date.parse(date);
    }
    this.setState({
      auditDate: {
        // startDate: moment(val[0]._d).format("X"),
        // endDate: moment(val[1]._d).format("X"),
        startDate: epoch(val[0]._d),
        endDate: epoch(val[1]._d),
      },
    });
    // console.log("data formate", this.state.auditDate);
  };

  auditTypeList = async () => {
    let token = decryptData(localStorage.token);

    try {
      document.toggleLoading(true);

      let url = `audit/getAuditTypes`;
      let response = await getCommonApiStructure("post", url, {
        Authorization: token,
      });
      if (response.status === 200) {
        let data = response.data.auditTypeList;

        let dataarr = [];
        for (const property in data) {
          if (data.hasOwnProperty(property)) {
            dataarr.push({
              label: data[property],
              value: data[property],
            });
          }
        }
        this.setState({ auditTypeData: dataarr });
      }
    } catch (error) {
      message.error("Unable to fetch details, please try after sometime");
    }
    document.toggleLoading();
  };
  generateAudit = async () => {
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);

    if (orgId) {
      try {
        document.toggleLoading(true);

        let startDate = "";
        let endDate = "";
        if (this.state.auditDate) {
          startDate = this.state.auditDate.startDate;
          endDate = this.state.auditDate.endDate;
        }

        let url = `audit/getAuditUrl?category=${this.state.auditTypeSelected}&orgId=${orgId}&startTime=${startDate}&endTime=${endDate}&auditName=${this.state.auditName}&empCount=${this.state.numEmp}`;
        let response = await getCommonApiStructure("get", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          this.setState({
            questions: data.questions,
            generatedLink: data.link,
            auditId: data.auditId,
          });
          this.props.parentCallback(data.auditId);
        }
      } catch (error) {
        message.error("Unable to fetch details, please try after sometime");
      }
      document.toggleLoading();
    }
  };

  render() {
    const isVisible = this.props.isVisible;
    const copyContent = async () => {
      let text = document.getElementById("myText").innerHTML;
      try {
        await navigator.clipboard.writeText(text);
        alert("Content copied to clipboard");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    };
    // console.log("dddd", this.state.auditDate);
    return (
      <>
        <div
          className={[
            classes.createAudit_flex,
            isVisible !== "create" ? classes.hide : "",
          ].join(" ")}
        >
          <div className={classes.selactAudit}>
            <Space wrap>
              <Select
                defaultValue="Select Audit"
                style={{
                  width: 200,
                }}
                onChange={this.auditTypeSelected}
                options={this.state.auditTypeData}
              />
            </Space>
          </div>
          <div style={{ marginLeft: "10px" }} className={classes.auditName}>
            <span className={classes.auditNameSpan}> Audit Name</span>
            <input
              type="text"
              name="auditName"
              className={classes.auditNameInput}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div className={classes.dataSel}>
            <Space direction="vertical" size={12}>
              <RangePicker onChange={(val) => this.dateSet(val)} />
            </Space>
          </div>

          <div className={classes.auditName}>
            <span className={classes.auditNameSpan22}>
              Number of employees to audit
            </span>
            <input
              type="text"
              name="numEmp"
              className={classes.auditNameInputEm22}
              pattern="[0-9]*"
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div>
            <button
              style={{ width: "200px" }}
              className={classes.auditBtn}
              onClick={(e) => this.generateAudit()}
            >
              Generate Audit
            </button>
          </div>
        </div>

        <div
          className={isVisible === "create" ? classes.questionsF : classes.hide}
        >
          {this.state.generatedLink ? (
            <div style={{ position: "relative" }}>
              <a
                id="myText"
                className={classes.linkAudit}
                href={this.state.generatedLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.generatedLink}
              </a>
              <div
                onClick={() => copyContent()}
                style={{
                  position: "absolute",
                  top: "9px",
                  right: "6px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={iconCopy}
                  alt="copy icon"
                  width="25px"
                  height="25px"
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div
          className={isVisible === "create" ? classes.questions : classes.hide}
        >
          <div className={classes.questionsG}>
            {this.state.questions.map((ele, index) => (
              <p className={classes.question} key={index}>
                {ele.question}
              </p>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default CreateAudit;
