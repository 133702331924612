import React, { Component } from "react";
import classes from "./career.module.css";

class ActivityProgess extends Component {
  render() {
    // console.log("gg", this.props.visualParts);
    return (
      <>
        <div className={classes.progressVisualFull}>
          {this.props.visualParts.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  //   width: widths[index],
                  width: item.percentage,
                  height: "100%",
                  // setting the actual color of bar part
                  backgroundColor: item.color,
                }}
                className="progressVisualPart"
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default ActivityProgess;
