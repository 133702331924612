import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Q1",
    uv: 400,
    // pv: 240,
    // amt: 2400,
  },
  {
    name: "Q2",
    uv: 300,
    pv: 139,
    amt: 2210,
  },
  {
    name: "Q3",
    uv: 200,
    pv: 980,
    amt: 2290,
  },
  {
    name: "Q4",
    uv: 278,
    pv: 390,
    amt: 2000,
  },
];

export default class AttritionAnalytics extends PureComponent {
  render() {
    // console.log("ffff", this.props);

    return (
      <>
        <h3 style={{ marginBottom: "12%" }}>{this.props.tableTata}</h3>

        <ResponsiveContainer width="80%" height="70%">
          <BarChart
            width={150}
            height={40}
            data={this.props.attritionAnalyticsData}
            barSize={35}
          >
            <Bar
              dataKey="uv"
              fill="#8884d8"
              margin={{ top: 10, left: 10, right: 0, bottom: 20 }}
            />
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 40, right: 30 }}
            />
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 40, right: 30 }}
            />
            <YAxis />
            <Tooltip />
            {/* <Legend /> */}
          </BarChart>
        </ResponsiveContainer>

        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              bottom: "-20px",
              left: "50%",
              transform: "translate(-100%, 50%)",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundColor: "rgb(136, 132, 216)",
              }}
            ></div>
            <p style={{ margin: "0" }}>Quarters</p>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "300px",
              left: "-4%",
              transform: " rotate(-90deg)",
              display: "flex",
              // flexDirection: "column",
              gap: "10px",
            }}
          >
            <p style={{ margin: "0", fontWeight: "500" }}>Employee Quit</p>
          </div>
        </div>
      </>
    );
  }
}
