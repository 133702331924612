import React, { Component } from "react";
import { Cell, Pie, PieChart } from "recharts";
import classes from "./audit.module.css";
import ProgressBar from "../analytics/admin/OverAllSkillGap/ProgressBar";
import TopRiskEmp from "./TopRiskEmp";
import { Select, Button, Progress, Rate, Space, message } from "antd";
import { decryptData } from "../../utils/encryptDecrypt";
import { getCommonApiStructure } from "../../core/apiClient/organization/organizationClient";

const data = [
  { name: "Group A", value: 300 },
  { name: "Group B", value: 400 },
  { name: "Group C", value: 500 },
];

// const COLORS = ["#00BCD4", "#009688", "#4CAF50", "#FFC107"];
const COLORS = ["#BDA4FC", "#ff8c96", "#96ACF9", "#FCDC00"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

class AuditReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auditSetNameData: [],
      auditNameData: [],
      auditTypeData: [],
      auditTypeSelected: "",
      auditNameSelected: "",
      auditScoreCard: null,
      themeScoreCard: [],
      chartCard: [],
      unhappyEmployees: [],
      recordLength: 0,
      auditId: "",
      chartTitle: "",
    };
  }

  componentDidMount = () => {
    this.auditListLoad();
    // this.auditScoreCardLoad();
    // this.themeScoreCardLoad();
  };

  auditTypeSelected = (value) => {
    this.setState({ auditTypeSelected: value });

    let auditSetNameData = this.state.auditSetNameData;

    let dataNamearr = [];
    let specificCross = auditSetNameData[value];
    // console.log(specificCross);

    for (let index = 0; index < specificCross.length; index++) {
      const element = specificCross[index];

      dataNamearr.push({
        label: element.auditName,
        value: element.auditId,
      });
    }

    this.setState({ auditNameData: dataNamearr });
    this.setState({ auditNameSelected: dataNamearr[dataNamearr.length - 1] });

    //api refresh
    this.setState({ auditId: this.state.auditNameSelected.value }, () => {
      this.auditScoreCardLoad(dataNamearr[dataNamearr.length - 1].value);
      this.themeScoreCardLoad(dataNamearr[dataNamearr.length - 1].value);
      this.chartTableLoad(dataNamearr[dataNamearr.length - 1].value);
    });
  };
  auditNameSelected = (value) => {
    this.setState({ auditNameSelected: value });

    //api refresh
    this.setState({ auditId: this.state.auditNameSelected.value }, () => {
      this.auditScoreCardLoad(value);
      this.themeScoreCardLoad(value);
      this.chartTableLoad(value);
    });
  };

  auditListLoad = async () => {
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);

    if (orgId) {
      try {
        document.toggleLoading(true);

        let url = `audit/getAuditList/organizationId/${orgId}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });
        if (response.status === 200) {
          let data = response.data;
          let namedata = data.data;
          let dataType = data.auditTypes;

          let dataTypearr = [];
          for (const property in dataType) {
            if (dataType.hasOwnProperty(property) && dataType[property] != "") {
              dataTypearr.push({
                label: dataType[property],
                value: dataType[property],
              });
            }
          }

          let dataNamearr = [];
          let specificCross = namedata[dataType[dataType.length - 1]];
          // console.log(specificCross);

          if (specificCross !== undefined) {
            for (let index = 0; index < specificCross.length; index++) {
              const element = specificCross[index];
              if (element.auditName != "" && element.auditId != "") {
                dataNamearr.push({
                  label: element.auditName,
                  value: element.auditId,
                });
              }
            }

            // this.setState({ auditTypeData: dataTypearr, auditNameData: dataNamearr });
            this.setState({
              auditTypeData: dataTypearr,
              auditNameData: dataNamearr,
              auditSetNameData: namedata,
            });
            this.setState({
              auditTypeSelected: dataTypearr[dataTypearr.length - 1],
            });
            this.setState({
              auditNameSelected: dataNamearr[dataNamearr.length - 1],
            });
            // console.log(dataNamearr[(dataNamearr.length - 1)]);
            //api refresh
            this.setState(
              { auditId: this.state.auditNameSelected.value },
              () => {
                this.auditScoreCardLoad(
                  dataNamearr[dataNamearr.length - 1].value
                );
                this.themeScoreCardLoad(
                  dataNamearr[dataNamearr.length - 1].value
                );
                this.chartTableLoad(dataNamearr[dataNamearr.length - 1].value);
              }
            );
          } else {
            this.setState({
              auditTypeData: [],
              auditNameData: [],
              auditSetNameData: "",
            });
            this.setState({
              auditTypeSelected: [],
            });
            this.setState({
              auditNameSelected: [],
            });
          }
        }
      } catch (error) {
        console.log("=== SDP Error - 1 -> ", error);
        message.error("Unable to fetch details, please try after sometime - 1");
      }
      document.toggleLoading();
    }
  };
  auditScoreCardLoad = async (auditId = "") => {
    // console.log(this.state.auditId);
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);

    if (orgId) {
      try {
        document.toggleLoading(true);

        let url = `audit/getCompanyScore/organizationId/${orgId}?aId=${auditId}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          this.setState({ auditScoreCard: data });
        }
      } catch (error) {
        message.error("Unable to fetch details, please try after sometime - 2");
      }
      document.toggleLoading();
    }
  };

  themeScoreCardLoad = async (auditId = "") => {
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);

    if (orgId) {
      try {
        document.toggleLoading(true);

        let url = `audit/themeScore/organizationId/${orgId}?aId=${auditId}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          let dataarr = [];
          for (const property in data) {
            if (data.hasOwnProperty(property)) {
              dataarr.push({
                label: property,
                value: data[property],
              });
            }
          }

          this.setState({ themeScoreCard: dataarr });
          // console.log(this.state.themeScoreCard);
        }
      } catch (error) {
        message.error("Unable to fetch details, please try after sometime - 3");
      }
      document.toggleLoading();
    }
  };
  chartTableLoad = async (auditId = "") => {
    let orgId = decryptData(localStorage.orgId);
    let token = decryptData(localStorage.token);

    if (orgId) {
      try {
        document.toggleLoading(true);

        let url = `audit/getHappinessDistribution/organizationId/${orgId}?aId=${auditId}`;
        let response = await getCommonApiStructure("post", url, {
          Authorization: token,
        });

        if (response.status === 200) {
          let data = response.data;

          let dataarr = [
            {
              name: "happyPercentage",
              value: data.happyPercentage != "NaN" ? data.happyPercentage : 0,
            },
            {
              name: "unhappyPercentage",
              value:
                data.unhappyPercentage != "NaN" ? data.unhappyPercentage : 0,
            },
          ];
          // for (const property in data) {
          //   if (data.hasOwnProperty(property)) {
          //     dataarr.push({
          //       label: property,
          //       value: data[property],
          //     });
          //   }
          // }

          console.log("173/", data);

          this.setState({ chartCard: dataarr });
          this.setState({ legendData: data.legend });
          this.setState({ unhappyEmployees: data.unhappyEmployees });
          this.setState({ recordLength: data.recordLength });
          {
            /* Setting the chartTitle */
          }
          this.setState({ chartTitle: data.title });

          // console.log(this.state.themeScoreCard);
        } else {
          this.setState({ chartCard: [] });
          this.setState({ legendData: data.legend });
          this.setState({ unhappyEmployees: [] });
          this.setState({ recordLength: 0 });
          this.setState({ chartTitle: "NO DATA AVAILABLE" });
        }
      } catch (error) {
        console.log("=== SDP error -> ", error.message);
        message.error("There are no audits");
        // message.error("Unable to fetch details, please try after sometime");
      }
      document.toggleLoading();
    }
  };

  render() {
    const isVisible = this.props.isVisible;
    // console.log("data", this.state.chartCard);
    return (
      <div
        className={[
          classes.createAudit,
          isVisible === "audit" ? "" : classes.hide,
        ].join(" ")}
      >
        <div className={classes.reportCantainer}>
          <div className={classes.auditChart_left}>
            <div className={classes.audit_right_header}>
              <div className={classes.auditName}>
                <span className={classes.auditNameSpan}>Audit Type</span>
                {/* <input type="text" className={classes.auditNameInput} /> */}
                <Space wrap>
                  <Select
                    defaultValue="Select Audit"
                    value={
                      this.state.auditTypeSelected != ""
                        ? this.state.auditTypeSelected
                        : "Select Audit"
                    }
                    style={{
                      width: 120,
                    }}
                    onChange={this.auditTypeSelected}
                    options={this.state.auditTypeData}
                  />
                </Space>
              </div>
              <div className={classes.auditName}>
                <span className={classes.auditNameSpan}>Audit Name</span>
                {/* <input type="text" name="auditName" className={classes.auditNameInput} onChange={(e) => this.handleChange(e)} /> */}
                <Space wrap>
                  <Select
                    defaultValue="Audit Name"
                    value={
                      this.state.auditNameSelected != ""
                        ? this.state.auditNameSelected
                        : "Audit Name"
                    }
                    style={{
                      width: 120,
                    }}
                    onChange={this.auditNameSelected}
                    options={this.state.auditNameData}
                  />
                </Space>
              </div>
            </div>
            <div
              style={{ display: this.state.auditId != "" ? "block" : "none" }}
              className={classes.audit_report_score}
            >
              <p className={classes.a_score}>
                Company Score :{" "}
                {this.state.auditScoreCard
                  ? this.state.auditScoreCard.companyScore
                  : 0}{" "}
              </p>
              <div className={classes.audit_start}>
                <Rate
                  disabled
                  style={{ fontSize: "35px" }}
                  allowHalf
                  value={
                    this.state.auditScoreCard
                      ? this.state.auditScoreCard.companyScore
                      : 0
                  }
                />
              </div>
              <div className={classes.scoreValue}>
                <div className={classes.score_card}>
                  <p className={classes.score_num}>
                    {this.state.auditScoreCard
                      ? this.state.auditScoreCard.totalEmployees[0].empCount
                      : 0}
                  </p>
                  <p className={classes.score_text}>TOTAL USERS AUDITED</p>
                </div>
                <div className={classes.score_card}>
                  <p className={classes.score_num}>
                    {this.state.auditScoreCard
                      ? this.state.auditScoreCard.participatedEmployees[0]
                          .participationCount
                      : 0}
                  </p>
                  <p className={classes.score_text}> TOTAL RESPONDENTS</p>
                </div>
                {/* <div className={classes.score_card}>
                  <p className={classes.score_num}>90</p>
                  <p className={classes.score_text}>COMPLETED</p>
                </div> */}
              </div>
            </div>
          </div>
          <div
            style={{ display: this.state.auditId != "" ? "block" : "none" }}
            className={classes.auditChart_right}
          >
            {/* <h2 className={classes.headingPieChart}>Precentage Of Employees</h2> */}
            {/* NOTE: Picking up the Title Dynamically from DB */}
            <h2 className={classes.headingPieChart}>{this.state.chartTitle}</h2>
            <div
              className={classes.legendAudit}
              style={{ position: "absolute", width: "100%", float: "right" }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor: "#BDA4FC",
                    // backgroundColor: "#ff8c96",
                  }}
                ></div>
                <p style={{ margin: "0" }}>{this.state.legendData?.key1}</p>
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    width: "15px",
                    height: "15px",
                    // backgroundColor: "#BDA4FC",
                    backgroundColor: "#ff8c96",
                  }}
                ></div>
                <p style={{ margin: "0" }}>{this.state.legendData?.key2}</p>
              </div>
            </div>
            {this.state.chartCard.length > 0 ? (
              <PieChart width={300} height={300} style={{ margin: "10%" }}>
                <Pie
                  data={this.state.chartCard}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={120}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {COLORS.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            ) : (
              <p>No Data</p>
            )}
          </div>
        </div>
        {/* theme Career */}
        <div style={{ display: this.state.auditId != "" ? "block" : "none" }}>
          {this.state.themeScoreCard.map((ele, index) => (
            <div key={index} className={classes.themeGrowth}>
              <div style={{ width: "15%" }} className={classes.themeText}>
                <p className={classes.themePara}>
                  {/* Theme Career Growth <b>{` - ${ele.label}`}</b> */}
                  Theme <b>{` - ${ele.label}`}</b>
                </p>
                <p className={classes.themePara}>
                  Average : {ele.value.average}
                </p>
              </div>

              <div className={classes.themeProgressbar}>
                <Space direction="vertical">
                  <Progress
                    percent={ele.value.percentage}
                    size={[`${ele.value.percentage}`, `${ele.value.average}`]}
                    showInfo={true}
                    status="normal"
                  />
                </Space>
              </div>

              <div className={classes.themeResult}>
                <div className={classes.text_color}>
                  <p className={classes.theme_para1}>
                    No of Positive Responses
                  </p>
                  <p className={classes.theme_para1}>{ele.value.postive}</p>
                </div>
                <div className={classes.text_color}>
                  <p className={classes.theme_para2}>
                    No of Neutral Responses{" "}
                  </p>
                  <p className={classes.theme_para2}>{ele.value.neutral}</p>
                </div>
                <div className={classes.text_color}>
                  <p className={classes.theme_para3}>
                    No of Negative Responses{" "}
                  </p>
                  <p className={classes.theme_para3}>{ele.value.negative}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div style={{ display: this.state.auditId != "" ? "block" : "none" }}>
          <TopRiskEmp
            tblData={this.state.unhappyEmployees}
            totalLength={this.state.recordLength}
          />
        </div>
        {/* <div className={classes.emplyeeaudit}>
          <div className={classes.emp_data}>
            <h2 className={classes.text_employee}> EMPLOYEE AUDIT SCREEN</h2>
            <div className={classes.audit_right_header}>
              <div className={classes.auditName}>
                <span className={classes.auditNameSpan}> Name</span>
                <input type="text" className={classes.auditNameInput} />
              </div>
              <div className={classes.auditName}>
                <span className={classes.auditNameSpan}> Email</span>
                <input type="text" className={classes.auditNameInput} />
              </div>
            </div>
            <Button>Start Audit</Button>
          </div>
        </div>
        <div className={classes.last_text}>
          <p className={classes.question_text}>Question : 50</p>
        </div>
        <div className={classes.last_text}>
          <p className={classes.ques_dwon_text}>1 Question</p>
        </div> */}
      </div>
    );
  }
}

export default AuditReport;
