import React, { Component } from "react";
import classes from "./matrix.module.css";

class Matrix extends Component {
  render() {
    // console.log("066", this.props.compentencyMatrix);
    // console.log("06776", this.props.compentencyMatrix.message);
    return (
      <>
        <div className={classes.matrix_sec}>
          <div className={classes.matrix_div}>
            <div className={classes.matrix_div_item}>
              <p className={classes.valueup}>
                {this.props.compentencyMatrix.message !== undefined
                  ? this.props.compentencyMatrix.message.singleAssessment
                  : 0}
              </p>
              <p className={classes.mat_label}>Taken One Assessments</p>
            </div>
            <div className={classes.matrix_div_item}>
              <p className={classes.valueup}>
                {this.props.compentencyMatrix.message !== undefined
                  ? this.props.compentencyMatrix.message.twoAssessments
                  : 0}
              </p>
              <p className={classes.mat_label}>Taken Two Assessments</p>
            </div>
          </div>
          <div className={classes.matrix_div_down}>
            <div className={classes.down_box}>
              <p style={{ color: "#00A19D" }} className={classes.mat_value}>
                Beginner
              </p>
              <p className={classes.mat_value}>
                {this.props.compentencyMatrix.message !== undefined
                  ? this.props.compentencyMatrix.message.Beginner
                  : 0}
              </p>
            </div>
            <div className={classes.down_box}>
              <p style={{ color: "#6F69AC" }} className={classes.mat_value}>
                Intermediate
              </p>
              <p className={classes.mat_value}>
                {this.props.compentencyMatrix.message !== undefined
                  ? this.props.compentencyMatrix.message.Intermediate
                  : 0}
              </p>
            </div>
            <div className={classes.down_box}>
              <p style={{ color: "#FFB319" }} className={classes.mat_value}>
                Expert
              </p>
              <p className={classes.mat_value}>
                {this.props.compentencyMatrix.message !== undefined
                  ? this.props.compentencyMatrix.message.Expert
                  : 0}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Matrix;
